



















import { defineComponent } from '@vue/composition-api'

import PageView from '@/components/templates/h/common/tran-ref-store/PageView.vue'
import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import {
  TranRefStoreOutputCondition,
  TranRefStoreExtractCondition,
} from '@/types/entities'
import {
  TemplateFile,
  ResourceType,
  Where,
  WhereOp,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
  VUiPTranRefStoresCountDocument,
} from '@/types/generated/graphql'
import {
  EXPORT_FILENAME,
  OUTPUT_COND_DELETE,
  OUTPUT_COND_ITEM,
  OUTPUT_COND_STORE,
  OUTPUT_EXTRACT_TARGET_DEFAULT,
  SELECT_MAX_COUNT,
} from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'
import { addDayYMD } from '@/utils/mixin'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
import Toast from '@/utils/toast'
const DEFAULT_OUTPUT_CONST = {
  itemOutput: [OUTPUT_COND_ITEM.all, OUTPUT_COND_ITEM.shobunrui],
  delOutput: [],
}
const DEFAULT_EXTRACT_CONST = {
  validDate: [addDayYMD(1), '2999-12-31'],
}

export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      defaultDateValue: DEFAULT_EXTRACT_CONST.validDate,
      resourceType: ResourceType.UiPTranRefStore,
      templateFile: TemplateFile.UiPTranRefStore_01,
      extractCondition: {
        validDate: DEFAULT_EXTRACT_CONST.validDate,
        /** 参照元店舗 */
        sourceStores: [],
        /** 参照商品 */
        refItemShobunruis: [],
      },
      outputCondition: DEFAULT_OUTPUT_CONST,
      outputUseList: [
        {
          outputCondType: 'Item',
          displayItems: DEFAULT_OUTPUT_CONST.itemOutput,
          defaultSelected: DEFAULT_OUTPUT_CONST.itemOutput,
          disabledItems: [],
        },
        {
          outputCondType: 'Delete',
          displayItems: [OUTPUT_COND_DELETE.includeDelete],
          defaultSelected: DEFAULT_OUTPUT_CONST.delOutput,
          disabledItems: [],
        },
      ],
      totalCount: null,
      exportSpinner: false,
    }
  },
  methods: {
    changeExtractCondition(cond: TranRefStoreExtractCondition) {
      console.log('changeExtractCondition', cond)
      this.extractCondition = cond
      this.requestCount()
    },
    changeOutputCondition(cond: TranRefStoreOutputCondition) {
      console.log('changeOutputCondition', cond)
      this.outputCondition = cond
      this.requestCount()
    },
    async requestCount() {
      this.exportSpinner = true
      this.totalCount = null
      if (this.checkRequired(false)) {
        try {
          const result = await this.$apollo.query({
            query: VUiPTranRefStoresCountDocument,
            variables: { where: this._createWhere() },
          })
          const data = result.data.vUiPTranRefStores.totalCount
          this.totalCount = data.toString()
          this.exportSpinner = false
        } catch (e) {
          console.error(e)
          this.exportSpinner = false
          this.totalCount = '-'
        }
      } else {
        this.exportSpinner = false
        this.totalCount = '-'
      }
    },
    //　入力チェック
    checkRequired(showToast: boolean) {
      // 入力チェック開始
      let errMsg = []
      // 必須項目チェック
      if (
        !this.extractCondition.validDate[0] ||
        !this.extractCondition.validDate[1] ||
        (this.extractCondition.sourceStores.length == 0 &&
          this.extractCondition.refItemShobunruis.length == 0)
      ) {
        errMsg.push({
          message: this.$i18n.t('error.requiredItemNotInput'),
        })
      }
      // 選択数上限チェック
      if (this.extractCondition.sourceStoresCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.referrerStore'),
            selected: this.extractCondition.sourceStoresCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (this.extractCondition.refItemShobunruisCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.refItem'),
            selected: this.extractCondition.refItemShobunruisCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (errMsg.length) {
        if (showToast) {
          for (const msg of errMsg) {
            Toast.error(this, msg)
          }
        }
        // 入力チェックに引っかかった場合はエクスポートの要求を出さない
        return false
      }
      return true
    },
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({ files: files, resourceType: this.resourceType })
    },
    /**
     * エクスポート処理
     */
    async requestExport() {
      if (this.checkRequired(true)) {
        const query = this._createQueryString()
        await this.helper.export({
          query,
          jmespathQuery:
            'data.vUiPTranRefStores.edges[].node | map(&merge(`{"__update": null}`, @), @)',
          templateFile: this.templateFile,
          filenameExport: EXPORT_FILENAME.tran_ref_store,
        })
      }
    },
    /**
     * ファイル抽出Queryを構築する
     */
    _createQueryString(): string {
      const where = this._createWhere()
      const orderBy = this._createOrderByStatement()
      const rowQuery = `
        query c {
          vUiPTranRefStores(
            where: ${this.helper.generateQueryWhereString(where)}
            orderBy: ${this.helper.generateQueryOrderByString(orderBy)}
          ) {
            edges {
              node {
                inactiveFlg
                referrerStoreCd
                referrerStoreCdName
                refferenceStoreCd
                refferenceStoreCdName
                refLevel
                refLevelName
                category
                categoryName
                expireDate
                configuredFlg
                lastModifiedDatetime
                lastModifiedUserName
              }
            }
          }
        }
        `
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where: where,
      })
    },
    /**
     * ファイル抽出QueryのOrderByパラメータを構築する
     */
    _createOrderByStatement(): OrderBy[] {
      return [
        {
          field: 'referrerStoreCd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'category',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'refLevel',
          direction: OrderByDirection.Desc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'expireDate',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'refferenceStoreCd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
    },
    /**
     * ファイル抽出QueryのWhereパラメータを構築する
     */
    _createWhere() {
      const ITEM_SEARCH_CONDITION: Where[] = [
        // 有効日
        { field: ['expireDate'], op: WhereOp.Ge, value: [''] },
        // 参照店舗の抽出条件
        {
          field: ['referrerStoreCd'],
          op: WhereOp.In,
          value: [''],
        },
        // 参照商品の抽出条件
        {
          field: ['uiMCategory1s/category1Cd'],
          op: WhereOp.In,
          value: [''],
        },

        // 出力条件
        { field: ['refLevel'], op: WhereOp.In, value: [''] },
        { field: ['inactiveFlg'], op: WhereOp.In, value: [''] },
        // 設定行フラグ　1固定
        { field: ['configuredFlg'], op: WhereOp.In, value: ['1'] },
      ]
      const where = JSON.parse(JSON.stringify(ITEM_SEARCH_CONDITION))
      setWhereValue(where, 'expireDate', this.extractCondition.validDate[0], {
        ope: WhereOp.Ge,
      })
      setWhereValue(
        where,
        'referrerStoreCd',
        this.extractCondition.sourceStores
      )
      setWhereValue(
        where,
        'uiMCategory1s/category1Cd',
        this.extractCondition.refItemShobunruis
      )
      setWhereValue(where, 'refLevel', this.outputCondition.itemOutput)
      setWhereValue(where, 'inactiveFlg', [
        '0', // 削除されていないデータは必ず出力する
        ...this.outputCondition.delOutput,
      ])
      console.log({ where })
      return where
    },
  },
})
