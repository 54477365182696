




























import {
  defineComponent,
  PropType,
  ref,
  watch,
  computed,
} from '@vue/composition-api'

import HLine from '@/components/atoms/HLine.vue'
import DateGroup from '@/components/organisms/h/item/date/DateGroup.vue'
import ItemHierarchyGroup from '@/components/organisms/h/item/hierarchy/ItemHierarchyGroup.vue'
import ExtractConditionLayout from '@/components/organisms/h/layout/ExtractConditionLayout.vue'
import {
  TranRefStoreExtractCondition,
  dateGroupExtractCondition,
  itemHierarchyGroupExtractCondition,
  itemHierarchyUseList,
  dateRange,
} from '@/types/entities'

export default defineComponent({
  components: {
    ExtractConditionLayout,
    DateGroup,
    ItemHierarchyGroup,
    HLine,
  },
  props: {
    defaultDateValue: {
      type: Array as PropType<string[]>,
      default: () => ['', ''],
    },
  },
  setup(props, context) {
    /*********************************
     * 表示する選択肢
     *********************************/
    // Todo: TanaUseListにクラス、カテゴリ追加
    const storeUseList = ref<itemHierarchyUseList>([['Store']])
    const itemUseList = ref<itemHierarchyUseList>([['Shobunrui']])
    /*********************************
     * データ
     *********************************/
    const extractConditionOfDate = ref<dateGroupExtractCondition>({})
    const extractConditionOfStore = ref<itemHierarchyGroupExtractCondition>({})
    const extractConditionOfItem = ref<itemHierarchyGroupExtractCondition>({})
    /*********************************
     * emitデータの作成
     *********************************/
    // いずれかの抽出条件変更時の処理
    const whereValidData = ref<string[]>(props.defaultDateValue)
    const changeExtractCondition = () => {
      const base: TranRefStoreExtractCondition = {
        validDate:
          extractConditionOfDate.value.dateRange ||
          (props.defaultDateValue as dateRange),
        sourceStores: extractConditionOfStore.value.store || [],
        sourceStoresCount: extractConditionOfStore.value.storeCount || 0,
        refItemShobunruis: extractConditionOfItem.value.shobunrui || [],
        refItemShobunruisCount:
          extractConditionOfItem.value.shobunruiCount || 0,
      }
      let cond = { ...base }
      // console.log('changeExtractCondition', { cond })
      context.emit('change-extract-condition', cond)
      whereValidData.value =
        extractConditionOfDate.value.dateRange || props.defaultDateValue
    }

    /*********************************
     * 以下は各抽出条件変更時の処理
     *********************************/
    const changeExtractConditionOfDate = (
      params: dateGroupExtractCondition
    ) => {
      extractConditionOfDate.value = params
      changeExtractCondition()
    }
    const changeExtractConditionOfStore = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfStore.value = params
      changeExtractCondition()
    }
    const changeExtractConditionOfItem = (
      params: itemHierarchyGroupExtractCondition
    ) => {
      extractConditionOfItem.value = params
      changeExtractCondition()
    }

    const requiredItemInput = [
      '有効日：常に入力',
      '参照元店舗・参照商品のいずれかを一つ以上入力',
    ]

    // 日付のToを非活性にする
    const disabledTo = true
    return {
      disabledTo,
      requiredItemInput,
      itemUseList,
      storeUseList,
      whereValidData,
      changeExtractConditionOfDate,
      changeExtractConditionOfItem,
      changeExtractConditionOfStore,
    }
  },
})
